// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    max-width: 116px;
}

.app-icons {
    max-width: 262px;
    margin: 20px 0;
}

.mobile-continue-msg {
    position: relative;
    font-size: var(--fs-small);
    text-align: center;
    margin: 20px 0;
}
.mobile-continue-msg > p {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 0 12px;
}
.mobile-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap-value);
}

.no-account-msg {
    font-size: var(--fs-small);
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/auth.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;AACtB","sourcesContent":[".logo {\n    max-width: 116px;\n}\n\n.app-icons {\n    max-width: 262px;\n    margin: 20px 0;\n}\n\n.mobile-continue-msg {\n    position: relative;\n    font-size: var(--fs-small);\n    text-align: center;\n    margin: 20px 0;\n}\n.mobile-continue-msg > p {\n    position: absolute;\n    top: -10px;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: white;\n    padding: 0 12px;\n}\n.mobile-btn-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: var(--gap-value);\n}\n\n.no-account-msg {\n    font-size: var(--fs-small);\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
