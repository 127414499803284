import { baseUrl } from "../types/config";
import { apiRoutes } from "../types/api-routes";
import service from "./service";

export const getRefreshToken = (data) => {
    return service({
        url: `${baseUrl}/${apiRoutes.REFRESH_TOKEN}`,
        data,
        method: "post",
    });
};

export const login = (data) => {
    return service({
        url: `${baseUrl}/${apiRoutes.LOGIN}`,
        data,
        method: "post",
    });
};
