import { useState, useEffect, useRef } from "preact/hooks";
import HomeLayout from "../../layout/HomeLayout";
import "./tryon.css";
import { useAvStore } from "../../store";
import { getErrorMessage } from "../../helpers/functions";
import { getAvatars } from "../../services/avatars";
import { getProductDetails, tryOn } from "../../services/products";
import { LS_AV_SKU, LS_AV_WIDGET_ID } from "../../types/types";
import DotsLoader from "../../components/DotsLoader";
import PreviewModal from "./PreviewModal";
import { getTryOnTips } from "../../services/settings";
import TipsModal from "./TipsModal";
import AvatarSelectModal from "./AvatarSelectModal";

export const TryOn = () => {
    const user = useAvStore((state) => state.user);
    const [productData, setProductData] = useState();
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [error, setError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [avatarsError, setAvatarsError] = useState();
    const [isLoadingAvatars, setLoadingAvatars] = useState(true);
    const [productError, setProductError] = useState("");
    const [isLoadingProduct, setLoadingProduct] = useState(true);
    const [avatarData, setAvatarData] = useState([]);
    const [sku, setSku] = useState("");
    const [widgetId, setWidgetId] = useState("");
    const [isPreviewShowing, setPreviewShowing] = useState(false);
    const [previewData, setPreviewData] = useState();
    const [isTipsModalShowing, setTipsModalShowing] = useState(false);
    const [isAvatarSelectModalShowing, setAvatarSelectModalShowing] =
        useState(false);
    const [tips, setTips] = useState("");
    const abortRef = useRef();

    useEffect(() => {
        let sku = localStorage.getItem(LS_AV_SKU);
        let widgetId = localStorage.getItem(LS_AV_WIDGET_ID);

        if (process.env.NODE_ENV === "development") {
            if (!sku || !widgetId) {
                sku = "AV00014-M-Blue";
                widgetId = "f3f1a65c-6304-4edd-bdb1-e117153d79e6";
            }
        }
        getAvatarList();
        if (widgetId && sku) {
            setWidgetId(widgetId);
            setSku(sku);
            getProductInfo(widgetId, sku);
        }

        return () => abortRef.current && abortRef.current.abort();
    }, []);

    useEffect(() => {
        if (isPreviewShowing) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }

        getTips();
    }, [isPreviewShowing]);

    const getTips = async () => {
        await getTryOnTips()
            .then((res) => {
                if (res?.data) {
                    setTips(res.data.tips);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getAvatarList = async () => {
        setAvatarsError("");
        setLoadingAvatars(true);
        await getAvatars()
            .then((res) => {
                if (res?.data) {
                    setAvatarData(res.data);
                    if (res.data.length > 0) {
                        setSelectedAvatar(res.data[0]);
                    }
                }
            })
            .catch((error) => {
                const message = getErrorMessage(error);
                setAvatarsError(message);
            })
            .finally(() => setLoadingAvatars(false));
    };

    const getProductInfo = async (widgetId, sku) => {
        setProductError("");
        setLoadingProduct(true);
        await getProductDetails({ widgetId, sku })
            .then((res) => {
                if (res?.data) {
                    setProductData(res.data);
                }
            })
            .catch((error) => {
                const message = getErrorMessage(error);
                setProductError(message);
            })
            .finally(() => {
                setLoadingProduct(false);
            });
    };

    const tryOnProduct = async () => {
        setError("");
        setLoading(true);
        setPreviewShowing(true);

        abortRef.current = new AbortController();
        await tryOn(
            {
                widgetId,
                sku,
                customerAvatarDetailId: selectedAvatar?.id || "",
            },
            abortRef.current.signal
        )
            .then((res) => {
                if (res?.data) {
                    setPreviewData(res.data);
                }
            })
            .catch((error) => {
                const message = getErrorMessage(error);
                setError(message);
            })
            .finally(() => setLoading(false));
    };

    return (
        <HomeLayout>
            <div className="tryon-header">
                <h1 className="page-title">
                    Welcome back{user ? `, ${user.firstName}!` : "!"}
                </h1>
                <button
                    className="tips-btn"
                    title="Helpful Tips"
                    onClick={() => setTipsModalShowing(true)}
                >
                    <img
                        src={require("../../assets/svg/icon_light-bulb.svg")}
                        alt="tips icon"
                    />
                </button>
            </div>

            <PreviewModal
                isLoading={isLoading}
                error={error}
                isOpen={isPreviewShowing}
                previewData={previewData}
                productData={productData}
                onClose={() => {
                    setPreviewShowing(false);
                    setPreviewData(null);
                    setLoading(false);
                    abortRef.current.abort();
                }}
            />

            <TipsModal
                isOpen={isTipsModalShowing}
                onClose={() => {
                    setTipsModalShowing(false);
                }}
                tips={tips}
            />
            <AvatarSelectModal
                isOpen={isAvatarSelectModalShowing}
                isLoadingAvatars={isLoadingAvatars}
                avatarsError={avatarsError}
                avatarData={avatarData}
                selectedAvatar={selectedAvatar}
                setSelectedAvatar={setSelectedAvatar}
                onClose={() => {
                    setAvatarSelectModalShowing(false);
                }}
            />
            <div
                style={{
                    flexGrow: 1,
                    justifyContent: "center",
                }}
                className="flex-col flex-grow"
            >
                <div className="flex-col">
                    <div className="image-container">
                        <div className="product-image__container">
                            {isLoadingProduct ? (
                                <div className="loader flex-grow pos-center">
                                    <DotsLoader />
                                </div>
                            ) : !productError ? (
                                <>
                                    <div className="product-image">
                                        <img
                                            src={productData?.imageUrl || ""}
                                            alt="Product to Try On"
                                        />
                                    </div>
                                    <p className="line-clamp-2 mt-2">
                                        {productData?.title}
                                    </p>
                                </>
                            ) : (
                                <div className="flex-col flex-grow pos-center">
                                    <p>Oops!</p>
                                    <p>
                                        Something went wrong loading the product
                                        details
                                    </p>
                                </div>
                            )}
                        </div>

                        <div className="relative" style={{ width: "100%" }}>
                            {selectedAvatar !== null ? (
                                <div className="product-image__container">
                                    <div className="avatar-image">
                                        <img
                                            src={selectedAvatar.frontAvatarUrl}
                                            alt="Try On Graphic"
                                        />
                                    </div>
                                    <p className="line-clamp-2">
                                        {selectedAvatar.name}
                                    </p>
                                </div>
                            ) : (
                                <img
                                    src={require("../../assets/images/graphic_tryon.png")}
                                    alt="Try On Graphic"
                                />
                            )}
                            <button
                                className="select-avatar-btn"
                                title="Select Avatar"
                                onClick={() =>
                                    setAvatarSelectModalShowing(true)
                                }
                            >
                                <img
                                    src={require("../../assets/svg/icon_edit-person.svg")}
                                    alt="shirt icon"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    <button
                        className="btn"
                        disabled={
                            selectedAvatar === null ||
                            !widgetId ||
                            !sku ||
                            isLoading ||
                            isPreviewShowing
                        }
                        onClick={tryOnProduct}
                    >
                        {isLoading ? "Generating preview..." : "Try on now"}
                    </button>
                </div>
            </div>
        </HomeLayout>
    );
};
