import React from "react";
import Modal from "../../components/Modal";
import DotsLoader from "../../components/DotsLoader";
import ErrorMessage from "../../components/ErrorMessage";

export default function AvatarSelectModal({
    isOpen,
    onClose,
    avatarData,
    avatarsError,
    isLoadingAvatars,
    selectedAvatar,
    setSelectedAvatar,
}) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Select Avatar"
            className="select-avatar-modal"
        >
            {!isLoadingAvatars && avatarsError && (
                <ErrorMessage message={avatarsError} />
            )}
            {!avatarsError && (
                <>
                    {isLoadingAvatars ? (
                        <>
                            <div
                                className="avatar-thumbnails__container loader flex-grow pos-center"
                                style={{ height: 100, marginBottom: 10 }}
                            >
                                <DotsLoader />
                            </div>
                            <p>Loading avatars...</p>
                        </>
                    ) : avatarData?.length > 0 ? (
                        <div className="avatar-thumbnails__container">
                            {avatarData.map((avatar) => {
                                return (
                                    <div
                                        className={`avatar-thumbnail ${
                                            selectedAvatar?.id === avatar.id
                                                ? "selected"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            setSelectedAvatar(avatar);
                                            onClose();
                                        }}
                                    >
                                        <img src={avatar.frontAvatarUrl} />
                                        <div className="avatar-tag">
                                            {avatar.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="avatar-thumbnails__message">
                            <p>
                                You have not added any avatars! Please go to the
                                app to get your avatar set up.
                            </p>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
}
