import axios from "axios";
import { clearSession, loadSession, saveSession } from "../store";
import { getRefreshToken } from "./authentication";
import { apiRoutes } from "../types/api-routes";

const service = axios.create();

service.interceptors.request.use(
    (config) => {
        const session = loadSession();
        if (session && session.access_token) {
            let headers = config.headers;
            if (headers) {
                headers.set("Authorization", `Bearer ${session.access_token}`);
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (res) => {
        return res?.data || res;
    },
    async (error) => {
        const session = loadSession();

        if (
            error.request?.status !== 401 ||
            !session ||
            !session.refresh_token
        ) {
            return Promise.reject(error);
        }

        // Handling 401 errors with the refresh token
        if (
            error.response?.config?.url &&
            error.response.config.url.includes(apiRoutes.REFRESH_TOKEN)
        ) {
            clearSession();
            window.location.href = "/";
        }

        await getRefreshToken({ refreshToken: session.refresh_token })
            .then((res) => {
                // handle challenge?
                const refreshedInfo = res.data?.loginResponse;
                if (refreshedInfo) {
                    saveSession({
                        access_token: refreshedInfo.accessToken,
                        refresh_token: refreshedInfo.refreshToken,
                    });
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error(error);
                clearSession();
                window.location.href = "/";
            });
    }
);

export default service;
