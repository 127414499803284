export default function LockIcon() {
    return (
        <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 10.3623V8.3623C6 5.0523 7 2.3623 12 2.3623C17 2.3623 18 5.0523 18 8.3623V10.3623"
                stroke="#313131"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17 22.3623H7C3 22.3623 2 21.3623 2 17.3623V15.3623C2 11.3623 3 10.3623 7 10.3623H17C21 10.3623 22 11.3623 22 15.3623V17.3623C22 21.3623 21 22.3623 17 22.3623Z"
                stroke="#313131"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.9965 16.3623H16.0054"
                stroke="#313131"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.9955 16.3623H12.0045"
                stroke="#313131"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.99451 16.3623H8.00349"
                stroke="#313131"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
