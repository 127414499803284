import { route } from "preact-router";
import AuthLayout from "../../../layout/AuthLayout";

export const ForgotPassword = () => {
    return (
        <AuthLayout>
            <div className="flex-col">
                <h1 className="page-title">Forgot your password?</h1>
                <p>Don't worry! It happens.</p>
                <p>
                    To reset your password, you need to access your application
                    and change your password there.
                </p>
                <img
                    className="app-icons self-center"
                    src={require("../../../assets/images/graphic_app-store.png")}
                    alt="App store icons"
                />
                <button
                    className="btn-light"
                    onClick={() => {
                        route("/");
                    }}
                >
                    Go Back to Login
                </button>
            </div>
        </AuthLayout>
    );
};
