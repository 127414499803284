import { useEffect, useState } from "preact/hooks";
import Modal from "../../components/Modal";
import DotsLoader from "../../components/DotsLoader";

export default function PreviewModal({
    isOpen,
    onClose,
    previewData,
    productData,
    error,
    isLoading,
}) {
    const [isProductPreviewShowing, setProductPreviewShowing] = useState(false);
    const [isMobileWidth, setIsMobileWidth] = useState(false);
    const recommendedPoints = getRecommendationPoints(
        previewData?.fitRecommendationModel?.recommendation
    );

    useEffect(() => {
        if (window.innerWidth < 480) setIsMobileWidth(true);
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    useEffect(() => {
        setProductPreviewShowing(false);
    }, [isMobileWidth]);

    const onResize = () => {
        const width = window.innerWidth;
        if (width >= 480) {
            setIsMobileWidth(false);
        } else {
            setIsMobileWidth(true);
        }
    };

    function getRecommendationPoints(recommendation) {
        if (!recommendation) return [];
        let points = recommendation.split("\n");
        points.pop();

        return points;
    }

    const onViewMiniPreview = () => {
        setProductPreviewShowing(!isProductPreviewShowing);
    };
    if (isLoading || error) {
        return (
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Generating Your Preview"
                className="preview-alert-modal"
            >
                {isLoading && (
                    <div className="preview-alert-modal__content">
                        <div
                            className="loader flex-grow pos-center"
                            style={{ height: 100, marginBottom: 10 }}
                        >
                            <DotsLoader />
                        </div>
                        <p>
                            We're getting your dressing room ready! Please allow
                            30 to 55 seconds for your avatar to get dressed.
                        </p>
                    </div>
                )}
                {!isLoading && error && (
                    <div className="flex preview-alert-modal__content">
                        <img
                            className="error-icon"
                            src={require("../../assets/svg/icon_error.svg")}
                            alt="Avease Logo"
                        />
                        <div>
                            <p>
                                Something has gone wrong while generating your
                                preview.
                            </p>
                            <p>Please try again.</p>
                        </div>
                    </div>
                )}
            </Modal>
        );
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Your Avatar Preview"
            className="preview-modal"
        >
            <div
                className={`preview-images ${
                    isMobileWidth ? "single-preview" : ""
                }`}
            >
                <div className="preview-image avatar-preview">
                    {previewData && (
                        <img src={previewData?.imageUrl} alt="Avatar preview" />
                    )}
                    {isMobileWidth && (
                        <>
                            <button
                                className="view-product-btn"
                                title="View Product"
                                onClick={onViewMiniPreview}
                            >
                                <img
                                    src={require("../../assets/svg/icon_shirt.svg")}
                                    alt="shirt icon"
                                />
                            </button>
                            {isProductPreviewShowing && (
                                <div className="product-mini-preview">
                                    <img
                                        src={productData?.imageUrl || ""}
                                        alt="Product to Try On"
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div
                    className="preview-image"
                    style={{
                        display: !isMobileWidth ? "block" : "none",
                    }}
                >
                    <img
                        src={productData?.imageUrl || ""}
                        alt="Product to Try On"
                    />
                </div>
            </div>
            {previewData?.fitRecommendationModel?.recommendation && (
                <div className="fit-recommendation">
                    <p className="fit-recommendation_title">
                        Fit Recommendation
                    </p>
                    <div className="fit-recommendation_container">
                        <div>
                            Your recommended size is{" "}
                            <div className="recommended-size">
                                {previewData.fitRecommendationModel.size}
                            </div>
                        </div>
                        <div className="recommended-points">
                            {recommendedPoints.map((point, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="recommended-point"
                                    >
                                        <img
                                            className="measure-icon"
                                            src={require("../../assets/svg/icon_measuring-tape.svg")}
                                            alt="measuring tape"
                                        />
                                        <p>{point}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}
