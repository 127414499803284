import CloseIcon from "./icons/CloseIcon";

export default function ErrorMessage({ message, className, style }) {
    if (message)
        return (
            <div className={`error-message ${className || ""}`} style={style}>
                <CloseIcon className="close-icon" />
                <p className="text-error">{message}</p>
            </div>
        );
}
