export default function EmailIcon({ className }) {
    return (
        <svg
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M17 20.8623H7C4 20.8623 2 19.3623 2 15.8623V8.8623C2 5.3623 4 3.8623 7 3.8623H17C20 3.8623 22 5.3623 22 8.8623V15.8623C22 19.3623 20 20.8623 17 20.8623Z"
                stroke="#313131"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M17 9.3623L13.87 11.8623C12.84 12.6823 11.15 12.6823 10.12 11.8623L7 9.3623"
                stroke="#313131"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
