// Must be the first import
if (process.env.NODE_ENV === "development") {
    // Must use require here as import statements are only allowed
    // to exist at top-level.
    require("preact/debug");
}
import "./instrument";

import { render } from "preact";
import { App } from "./app.jsx";
import "./styles/index.css";

render(<App />, document.getElementById("app"));
