// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-loader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 6px;
    display: flex;
}

.loader-dot {
    width: 12px;
    height: 12px;
    background-color: var(--clr-primary);
    border-radius: 100%;
    flex-shrink: 0;
}
.loader-dot.first {
    animation: 1.4s ease-in-out 0ms infinite bounce;
}
.loader-dot.second {
    animation: 1.4s ease-in-out 160ms infinite bounce;
}
.loader-dot.third {
    animation: 1.4s ease-in-out 320ms infinite bounce;
}

@keyframes bounce {
    50% {
        transform: translateY(-12px);
    }

    100% {
        transform: translateY(0px);
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,+CAA+C;AACnD;AACA;IACI,iDAAiD;AACrD;AACA;IACI,iDAAiD;AACrD;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".dots-loader {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    justify-content: center;\n    align-items: center;\n    gap: 6px;\n    display: flex;\n}\n\n.loader-dot {\n    width: 12px;\n    height: 12px;\n    background-color: var(--clr-primary);\n    border-radius: 100%;\n    flex-shrink: 0;\n}\n.loader-dot.first {\n    animation: 1.4s ease-in-out 0ms infinite bounce;\n}\n.loader-dot.second {\n    animation: 1.4s ease-in-out 160ms infinite bounce;\n}\n.loader-dot.third {\n    animation: 1.4s ease-in-out 320ms infinite bounce;\n}\n\n@keyframes bounce {\n    50% {\n        transform: translateY(-12px);\n    }\n\n    100% {\n        transform: translateY(0px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
