import { Link, route } from "preact-router";
import { useState } from "preact/hooks";
import AuthLayout from "../../../layout/AuthLayout";
import InputControl from "../../../components/InputControl";
import { login } from "../../../services/authentication";
import { saveSession, useAvStore } from "../../../store";
import { getErrorMessage } from "../../../helpers/functions";
import { REGEX_VALIDATION } from "../../../types/regex";
import ErrorMessage from "../../../components/ErrorMessage";
import { getProfile } from "../../../services/customers";
import "../auth.css";
import EmailIcon from "../../../components/icons/EmailIcon";
import LockIcon from "../../../components/icons/LockIcon";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isValid, setValid] = useState(false);
    const [error, setError] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError("");

        await login({ email, password: password.trim() })
            .then((res) => {
                const loginInfo = res.data?.loginResponse;

                if (loginInfo) {
                    const tokenInfo = {
                        access_token: loginInfo.accessToken,
                        refresh_token: loginInfo.refreshToken,
                    };

                    saveSession(tokenInfo);
                    getCustomerProfile();
                    // window.top.postMessage({ tokenInfo }, "*");
                    route("/try-on");
                }
            })
            .catch((error) => {
                const message = getErrorMessage(error);
                setError(message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCustomerProfile = async () => {
        await getProfile()
            .then((res) => {
                if (res) {
                    useAvStore.getState().setUser(res.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const validateEmail = (value) => {
        let regex = REGEX_VALIDATION.EMAIL;

        setTimeout(() => {
            setEmailError("");
            let result = regex.test(value);
            if (result || value === "") {
                setValid(true);
            } else {
                setValid(false);
                setEmailError("Invalid email address");
            }
        }, 700);
    };
    return (
        <AuthLayout>
            <form onSubmit={onSubmit} className="flex-col">
                <img
                    className="logo self-center"
                    src={require("../../../assets/svg/avease_logo_gradient.svg")}
                    alt="Avease Logo"
                />
                <InputControl
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="user@info.com"
                    errorMessage={emailError}
                    prefixIcon={<EmailIcon />}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(e.target.value);
                    }}
                />
                <InputControl
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Your password"
                    prefixIcon={<LockIcon />}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Link href="/forgot-password" className="link self-end">
                    Forgot Password?
                </Link>
                <ErrorMessage message={error} />
                <button
                    disabled={!email || !password || !isValid || isLoading}
                    className={`btn self-center ${isLoading ? "loading" : ""}`}
                    type="submit"
                >
                    {isLoading ? "Loading..." : "Login"}
                </button>
                <p className="no-account-msg">
                    Don't have an account?
                    <Link
                        href="/register"
                        className="link"
                        style={{ marginLeft: "4px" }}
                    >
                        Sign Up Here
                    </Link>
                </p>
            </form>
        </AuthLayout>
    );
};
