import React from "react";
import Modal from "../../components/Modal";

export default function TipsModal({ isOpen, onClose, tips }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Helpful Tips">
            <p>
                Not the result you expected? Here are some tips to improve your
                preview image.
            </p>
            <br />
            <div
                className="html-format"
                dangerouslySetInnerHTML={{
                    __html: tips || "",
                }}
            ></div>
        </Modal>
    );
}
