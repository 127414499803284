// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML Formatting */

.html-format ol {
    list-style-position: inside;
}

.html-format {
    padding-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/htmlFormat.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["/* HTML Formatting */\n\n.html-format ol {\n    list-style-position: inside;\n}\n\n.html-format {\n    padding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
