import { useState } from "preact/hooks";
import EyeIcon from "./icons/EyeIcon";
// import ClearIcon from "../assets/svg/icon_close-circle.svg?react";

export const InputLabel = ({ label, id, isRequired }) => {
    return (
        <div className="mb-1 font-medium">
            <label htmlFor={id}>{label}</label>
            {isRequired && <span className="text-red-500 ml-1">*</span>}
        </div>
    );
};
export default function InputControl({
    label,
    type = "text",
    name,
    placeholder,
    value,
    onChange,
    isRequired = false,
    prefixIcon,
    className,
    message,
    isTextArea,
    isResizable = false,
    maxLength,
    disabled,
    errorMessage,
    autoComplete,
    clearable = false,
    onClear,
}) {
    const [isHidden, setIsHidden] = useState(true);

    return (
        <div className={`flex flex-col ${className}`}>
            {label && (
                <InputLabel label={label} id={name} isRequired={isRequired} />
            )}
            <div className="input-container">
                {prefixIcon ? (
                    <span className="prefix-icon">{prefixIcon}</span>
                ) : (
                    ""
                )}
                {!isTextArea ? (
                    <input
                        style={{
                            paddingLeft: prefixIcon ? 48 : "",
                            paddingRight: clearable ? 40 : "",
                        }}
                        type={!isHidden && type === "password" ? "text" : type}
                        name={name}
                        id={name}
                        autoComplete={autoComplete}
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        onChange={onChange}
                    />
                ) : (
                    <textarea
                        className={`textarea-style ${
                            prefixIcon ? "pl-12" : ""
                        } ${isResizable ? "" : "resize-none"}`}
                        id={name}
                        name={name}
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        onChange={onChange}
                    ></textarea>
                )}
                {clearable && value && (
                    <button
                        type="button"
                        onClick={onClear}
                        title="Clear search"
                    >
                        {/* <ClearIcon className="absolute top-1/2 -translate-y-1/2 right-3 w-5 h-5 text-slate-500" /> */}
                    </button>
                )}
                {type === "password" && (
                    <button
                        type="button"
                        onClick={() => setIsHidden(!isHidden)}
                    >
                        <EyeIcon
                            isSlashed={!isHidden}
                            className="suffix-icon"
                        />
                    </button>
                )}
            </div>
            {message && (
                <p className="text-slate-600 text-sm tracking-wide">
                    {message}
                </p>
            )}
            {errorMessage && (
                <p className="text-red-600 text-sm">{errorMessage}</p>
            )}
        </div>
    );
}
