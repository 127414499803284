import { baseUrl } from "../types/config";
import { apiRoutes } from "../types/api-routes";
import service from "./service";

export const getTryOnTips = () => {
    return service({
        url: `${baseUrl}/${apiRoutes.TIPS}/`,
        method: "get",
    });
};
