import { Route, route } from "preact-router";
import { checkAuthentication } from "../store";
import { useEffect } from "preact/hooks";

export function ProtectedRoute(props) {
    const isAuthenticated = checkAuthentication();

    useEffect(() => {
        if (!isAuthenticated) route("/", true);
    }, [isAuthenticated]);

    return <Route {...props} />;
}
