import { useEffect } from "preact/hooks";
import Router, { Route } from "preact-router";
import { Login, ForgotPassword, SignUp } from "./pages";
import { createContext } from "preact";
import { TryOn } from "./pages/try-on/TryOn";
import { getProfile } from "./services/customers";
import { checkAuthentication, useAvStore } from "./store";
import { ProtectedRoute } from "./components/ProtectedRoute";

export const AuthContext = createContext({ isAuthenticated: false });

export function App() {
    const searchParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        const isAuthenticated = checkAuthentication();
        if (isAuthenticated) {
            getCustomerProfile();
        }
    }, []);

    const getCustomerProfile = async () => {
        await getProfile()
            .then((res) => {
                if (res) {
                    useAvStore.getState().setUser(res.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (searchParams.get("productSku")) {
            localStorage.setItem("avease-sku", searchParams.get("productSku"));
        } else {
            console.error("There was an issue retrieving product SKU.");
        }

        if (searchParams.get("widgetId")) {
            localStorage.setItem("avease-id", searchParams.get("widgetId"));
        } else {
            console.error("There was an issue retrieving widget Id.");
        }
    }, [searchParams]);

    return (
        <AuthContext.Provider>
            <Router>
                <Route path="/" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/register" component={SignUp} />
                <ProtectedRoute path="/try-on" component={TryOn} />
            </Router>
        </AuthContext.Provider>
    );
}
