import React from "react";

export default function DotsLoader() {
    return (
        <div className="dots-loader">
            <div className="loader-dot first"></div>
            <div className="loader-dot second"></div>
            <div className="loader-dot third"></div>
        </div>
    );
}
