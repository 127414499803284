import { baseUrl } from "../types/config";
import { apiRoutes } from "../types/api-routes";
import service from "./service";

export const getAvatars = () => {
    return service({
        url: `${baseUrl}/${apiRoutes.AVATARS}`,
        method: "get",
    });
};
