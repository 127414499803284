export function getErrorMessage(error, customizedDefault) {
    const defaultMessage =
        customizedDefault ||
        "Sorry, an unexpected error has occurred. Please try again or contact our team if the issue still persists.";
    let e = defaultMessage;
    if (error?.response?.data) {
        e =
            error.response.data.errorDetails ||
            error.response.data.title ||
            error.response.data.message ||
            error.response.data.errorMessage ||
            error.response.data;
    }
    if (error?.code === "ERR_NETWORK") {
        return "Sorry, we are unable to complete the action due to a network issue. Please check your connection or try again later.";
    }

    return e;
}

export const onIframeClose = () => {
    window.top.postMessage({ eventType: "close" }, "*");
};
