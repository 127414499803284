import { baseUrl } from "../types/config";
import { apiRoutes } from "../types/api-routes";

import service from "./service";

export const getProfile = () => {
    return service({
        url: `${baseUrl}/${apiRoutes.PROFILE}`,
        method: "get",
    });
};
