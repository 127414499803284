import "./layout.css";
import CloseIcon from "../components/icons/CloseIcon";
import { onIframeClose } from "../helpers/functions";
import { clearSession } from "../store";
import { route } from "preact-router";

export default function HomeLayout({ children }) {
    const onLogout = () => {
        clearSession();
        route("/");
    };
    return (
        <div className="container relative">
            <div className="logo-container">
                <img
                    className="logo-mini"
                    src={require("../assets/svg/avease_logo_no-text.svg")}
                    alt="Avease Logo"
                />
            </div>
            <button onClick={onIframeClose} className="close-icon__modal">
                <CloseIcon hasCircle={false} />
            </button>
            <button className="btn logout-btn" onClick={onLogout}>
                <img
                    className="btn-prefix-icon"
                    src={require("../assets/svg/icon_logout.svg")}
                    alt="logout icon"
                />
                <p>Logout</p>
            </button>
            <main className="container main-layout">{children}</main>
        </div>
    );
}
