import React from "react";

export default function EyeIcon({ isSlashed, className }) {
    if (isSlashed) {
        return (
            <svg
                className={className}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
            >
                <path
                    d="M14.5299 9.83235L9.46992 14.8923C8.81992 14.2423 8.41992 13.3523 8.41992 12.3623C8.41992 10.3823 10.0199 8.78235 11.9999 8.78235C12.9899 8.78235 13.8799 9.18235 14.5299 9.83235Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17.8198 6.13229C16.0698 4.81229 14.0698 4.09229 11.9998 4.09229C8.46984 4.09229 5.17984 6.17229 2.88984 9.77228C1.98984 11.1823 1.98984 13.5523 2.88984 14.9623C3.67984 16.2023 4.59984 17.2723 5.59984 18.1323"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.41992 19.8924C9.55992 20.3724 10.7699 20.6324 11.9999 20.6324C15.5299 20.6324 18.8199 18.5524 21.1099 14.9524C22.0099 13.5424 22.0099 11.1724 21.1099 9.76236C20.7799 9.24236 20.4199 8.75236 20.0499 8.29236"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.5099 13.0623C15.2499 14.4723 14.0999 15.6223 12.6899 15.8823"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.47 14.8923L2 22.3623"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M21.9998 2.3623L14.5298 9.8323"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg
            className={className}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
        >
            <path
                d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.9998 20.2707C15.5298 20.2707 18.8198 18.1907 21.1098 14.5907C22.0098 13.1807 22.0098 10.8107 21.1098 9.4007C18.8198 5.8007 15.5298 3.7207 11.9998 3.7207C8.46984 3.7207 5.17984 5.8007 2.88984 9.4007C1.98984 10.8107 1.98984 13.1807 2.88984 14.5907C5.17984 18.1907 8.46984 20.2707 11.9998 20.2707Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
