import { baseUrl } from "../types/config";
import { apiRoutes } from "../types/api-routes";
import service from "./service";

export const getProductDetails = ({ widgetId, sku }) => {
    return service({
        url: `${baseUrl}/${apiRoutes.PRODUCTS}/${widgetId}/${sku}`,
        method: "get",
    });
};

export const tryOn = (data, signal) => {
    return service({
        url: `${baseUrl}/${apiRoutes.TRY_ON}`,
        data,
        method: "post",
        signal,
    });
};
