import CloseIcon from "./icons/CloseIcon";

export default function Modal({ isOpen, onClose, children, title, className }) {
    return (
        isOpen && (
            <div>
                <div className="modal-overlay"></div>
                <div className={`modal-content ${className || ""}`}>
                    <div>
                        <h2 className="modal-title">{title}</h2>
                        <button
                            onClick={onClose}
                            className="close-icon__modal inner-modal"
                        >
                            <CloseIcon hasCircle={false} />
                        </button>
                    </div>
                    {/* Modal inner content */}
                    <div className="modal-content__inner">{children}</div>
                </div>
            </div>
        )
    );
}
