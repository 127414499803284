import Redirect from "../components/Redirect";
import { checkAuthentication } from "../store";
import "./layout.css";
import CloseIcon from "../components/icons/CloseIcon";
import { onIframeClose } from "../helpers/functions";

export default function AuthLayout({ children }) {
    const isAuthenticated = checkAuthentication();

    if (isAuthenticated) return <Redirect to="/try-on" />;

    return (
        <div className="auth-layout container">
            <div className="auth_panel-left">
                <img
                    src={require("../assets/images/graphic_login-screen.png")}
                    alt="Get Mobile App Graphic"
                />
            </div>
            <div className="auth_panel-right">
                <button onClick={onIframeClose} className="close-icon__modal">
                    <CloseIcon hasCircle={false} />
                </button>
                {children}
            </div>
        </div>
    );
}
