import { route } from "preact-router";
import AuthLayout from "../../../layout/AuthLayout";

export const SignUp = () => {
    return (
        <AuthLayout>
            <div className="flex-col">
                <h1 className="page-title">Sign Up Using our App!</h1>

                <p>
                    To create your account at Avease you need to download our
                    application available on the digital platforms below.
                </p>
                <img
                    className="app-icons self-center"
                    src={require("../../../assets/images/graphic_app-store.png")}
                    alt="App store icons"
                />
                <button
                    className="btn-light"
                    onClick={() => {
                        route("/");
                    }}
                >
                    Go Back to Login
                </button>
            </div>
        </AuthLayout>
    );
};
