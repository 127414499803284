import { route } from "preact-router";
import { useEffect } from "preact/hooks";

export default function Redirect({ to }) {
    useEffect(() => {
        route(to, true);
    }, []);

    return null;
}
