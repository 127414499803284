export const apiRoutes = {
    // AUTH & ACCOUNTS
    REFRESH_TOKEN: "api/v1/Accounts/refreshtoken",

    LOGIN: "api/v1/Customers/login",

    AVATARS: "api/v1/Customers/avatars",
    PROFILE: "api/v1/Customers/profile",

    // PRODUCTS

    PRODUCTS: "api/v1/Products/detail",
    TRY_ON: "api/v1/Products/tryon",

    // SETTINGS
    TIPS: "api/v1/Settings",
};
